import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import SupportImage from "../assets/images/support.jpg";
import WhiteLogo from "../assets/images/white-logo.png";

import useIsMobileScreenWidth from "../hooks/useIsMobile";

import "./Support.css";

const SupportWayCard = React.memo(({ number, content = <></> }) => {
    const { t } = useTranslation();

    return (
        <div className="full-width-space font-size-16">
            <div className="flex">
                <div className="support-way-number">{number}</div>
                <div className="support-way">
                    <div className="support-way-title">{t("way")}</div>
                    {content}
                </div>
            </div>
        </div>
    );
});

const Support = React.memo(() => {
    const { t } = useTranslation();
    const isMobile = useIsMobileScreenWidth();

    const privatBankHelp = t("privatBankHelp", { returnObjects: true });

    return (
        <div className="max-width-1270">
            <div
                className={`title flex donate-wrapper ${isMobile && "column"}`}
                style={{
                    paddingBottom: isMobile ? 0 : 20,
                }}>
                <div>{t("donateText")}</div>
                <img
                    loading="lazy"
                    src={WhiteLogo}
                    alt="logo"
                    className="white-logo"
                />
            </div>
            <Row className="full-width-space" gutter={24}>
                <Col span={isMobile ? 24 : 12}>
                    <div className="flex column support-wrapper">
                        <div className="title">{t("props.title")}</div>
                        {t("props.recipientAccount")}:
                        UA718201720313271004301094165
                        <br />
                        <br />
                        {t("props.recipient")}: Військова частина А4267
                        <br />
                        <br />
                        {t("props.recipientCode")}: 26615035
                        <br />
                        <br />
                        {t("props.bank")}: Держказначейська служба України м.
                        Київ
                        <br />
                        <br />
                        {t("props.purpose")}: Благодійний внесок
                    </div>
                    {isMobile && (
                        <img
                            loading="lazy"
                            alt="support"
                            src={SupportImage}
                            className="support-image desktop-support-image"
                            style={{ minHeight: 100 }}
                        />
                    )}
                </Col>
                {!isMobile && (
                    <Col span={12}>
                        <img
                            loading="lazy"
                            alt="support"
                            src={SupportImage}
                            className="support-image"
                        />
                    </Col>
                )}
            </Row>

            <div className="flex column">
                <div className="title">{t("howToHelp")}</div>
                <Row className="ful-width-space" gutter={[20, 20]} wrap>
                    <Col
                        className="support-way-column"
                        span={isMobile ? 24 : 12}>
                        <div className="flex column">
                            <SupportWayCard
                                number={1}
                                content={
                                    <ul className="flex column align-start privat-bank-support-wrapper">
                                        {privatBankHelp.map((item, idx) => (
                                            <li key={idx}>{item}</li>
                                        ))}
                                    </ul>
                                }
                            />
                        </div>
                    </Col>
                    <Col
                        span={isMobile ? 24 : 12}
                        style={{ minWidth: 300, width: "100%" }}>
                        <div className="flex column">
                            <SupportWayCard
                                number={2}
                                content={t("phoneHelp")}
                            />
                            <SupportWayCard
                                number={3}
                                content={t("cashHelp")}
                            />
                            <SupportWayCard number={4} content={t("atmHelp")} />
                            <SupportWayCard
                                number={5}
                                content={t("appsHelp")}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
});

export default Support;
