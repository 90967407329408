import { BrowserRouter, Route, Routes } from "react-router-dom";
import SiteContainer from "./containers/SiteContainer";

import "./App.css";

import Home from "./containers/Home";
import About from "./containers/About";
import Contacts from "./containers/Contacts";
import Support from "./containers/Support";
import TermsOfPolicy from "./containers/TermsOfPolicy";

const App = () => (
    <div className="App">
        <BrowserRouter>
            <SiteContainer>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/contacts" element={<Contacts />} />
                    <Route path="/policy" element={<TermsOfPolicy />} />
                </Routes>
            </SiteContainer>
        </BrowserRouter>
    </div>
);

export default App;
