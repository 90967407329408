import React from "react";
import { Carousel } from "antd";

import "./ImagesCarousel.css";

const ImagesCarousel = React.memo(({ images = [] }) => (
    <Carousel className="image-carousel" draggable>
        {images.map((image, idx) => (
            <div key={idx}>{image}</div>
        ))}
    </Carousel>
));

export default ImagesCarousel;
