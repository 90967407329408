import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    message,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "./common/DatePicker";
import { useForm } from "antd/es/form/Form";
import { sub } from "date-fns";
import axios from "axios";

import "./ApplyModal.css";
import { iOS } from "../helpers/platform";

const { Link } = Typography;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

const ApplyModal = ({ onOpen = () => {} }) => {
    const { t } = useTranslation();

    const defaultFormRule = {
        whitespace: true,
        required: true,
        message: t("isRequired"),
    };

    const [form] = useForm();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleClose = () => setIsOpen(false);
    const handleShow = () => {
        onOpen();
        setIsOpen(true);
    };

    const regions = t("regions", { returnObjects: true });
    const regionKeys = Object.keys(regions);

    const handleSubmit = (values) => {
        form.validateFields().then(() => {
            const {
                phoneNumber,
                firstName,
                lastName,
                midName,
                tgNickname,
                comment,
                birthDate,
            } = values || {};

            try {
                axios
                    .post("/create_application", {
                        ...values,
                        phoneNumber: `+380${phoneNumber}`,
                        firstName: firstName.trim(),
                        lastName: lastName.trim(),
                        midName: midName.trim(),
                        tgNickname: tgNickname ? `@${tgNickname.trim()}` : "",
                        comment: comment?.trim(),
                        birthDate: birthDate.toISOString().split("T")[0],
                    })
                    .then(({ data }) => {
                        if (data?.success) {
                            message.success(t("applyModal.successText"));
                            setIsOpen(false);
                        } else {
                            message.error(t("applyModal.errorText"));
                        }
                    });
            } catch (e) {
                console.warn(e);
            }
        });
    };

    const disableDates = (current) => {
        const youngest = sub(new Date(), { years: 18 });
        const oldest = new Date("01-01-1958");

        return current && (current > youngest || current < oldest);
    };

    return (
        <>
            <Modal
                maskClosable={false}
                width={"90%"}
                className="apply-modal"
                destroyOnClose
                open={isOpen}
                footer={null}
                onCancel={handleClose}
                title={t("applyModal.title")}
                bodyStyle={{ height: "100%" }}
                centered>
                <div className="description">{t("applyModal.description")}</div>
                <Form
                    scrollToFirstError
                    initialValues={{
                        birthDate: iOS()
                            ? "01-01-1980"
                            : new Date("01-01-1980"),
                    }}
                    rootClassName="content-wrapper"
                    onFinish={handleSubmit}
                    requiredMark
                    layout="vertical"
                    form={form}
                    autoComplete="false">
                    <Row gutter={24} wrap>
                        <Col span={12} className="form-column">
                            <Form.Item
                                rules={[defaultFormRule]}
                                name="lastName"
                                required
                                label={t("applyModal.form.lastName")}>
                                <Input
                                    placeholder={t("applyModal.form.lastName")}
                                />
                            </Form.Item>
                            <Form.Item
                                rules={[defaultFormRule]}
                                name="firstName"
                                required
                                label={t("applyModal.form.firstName")}>
                                <Input
                                    placeholder={t("applyModal.form.firstName")}
                                />
                            </Form.Item>
                            <Form.Item
                                rules={[defaultFormRule]}
                                name="midName"
                                required
                                label={t("applyModal.form.midName")}>
                                <Input
                                    placeholder={t("applyModal.form.midName")}
                                />
                            </Form.Item>
                            <Form.Item
                                rules={[{ ...defaultFormRule, type: "date" }]}
                                name="birthDate"
                                required
                                label={t("applyModal.form.birthDate")}
                                defaultValue={
                                    iOS()
                                        ? "01-01-1980"
                                        : new Date("01-01-1980")
                                }
                                getValueProps={(value) =>
                                    iOS() ? new Date(value) : value
                                }>
                                <DatePicker
                                    showToday={false}
                                    changeOnBlur
                                    allowClear={false}
                                    placeholder={"dd/mm/yyyy"}
                                    format={dateFormatList}
                                    disabledDate={disableDates}
                                    defaultPickerValue={sub(new Date(), {
                                        years: 20,
                                    })}
                                />
                            </Form.Item>
                            <Form.Item
                                rules={[
                                    defaultFormRule,
                                    {
                                        pattern: "^[1-9]{1}\\d{8}$",
                                        message: t("incorrectPhone"),
                                    },
                                ]}
                                name="phoneNumber"
                                required
                                label={t("applyModal.form.phoneNumber")}
                                getValueFromEvent={(e) => {
                                    const value = e.target.value;
                                    if (value?.startsWith("+380")) {
                                        return value.replace("+380", "");
                                    } else if (value?.startsWith("0")) {
                                        return value.slice(1, 10);
                                    }

                                    return value;
                                }}>
                                <Input
                                    maxLength={9}
                                    type="tel"
                                    addonBefore="+380"
                                    placeholder={t(
                                        "applyModal.form.phoneNumber",
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="form-column">
                            <Form.Item
                                rules={[
                                    {
                                        pattern: "^[A-Za-z0-9_]{5,32}$",
                                        message: t(),
                                    },
                                ]}
                                getValueFromEvent={(e) => {
                                    const value = e.target.value;
                                    if (value?.startsWith("@")) {
                                        return value.replace("@", "");
                                    }

                                    return value;
                                }}
                                name="tgNickname"
                                label={t("applyModal.form.tgNickname")}>
                                <Input
                                    maxLength={32}
                                    addonBefore="@"
                                    placeholder={t(
                                        "applyModal.form.tgNickname",
                                    )}
                                />
                            </Form.Item>
                            <Form.Item
                                rules={[defaultFormRule]}
                                name="region"
                                required
                                label={t("applyModal.form.region")}>
                                <Select
                                    defaultActiveFirstOption
                                    placeholder={t("applyModal.form.region")}
                                    options={regionKeys.map((regionKey) => ({
                                        value: regions[regionKey],
                                        label: regions[regionKey],
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item
                                rules={[
                                    {
                                        ...defaultFormRule,
                                        type: "boolean",
                                        validator: async (a, val) => {
                                            if (typeof val !== "boolean")
                                                throw new Error();
                                        },
                                    },
                                ]}
                                name="isMilitaryServiceman"
                                required
                                label={t(
                                    "applyModal.form.isMilitaryServiceman",
                                )}>
                                <Radio.Group
                                    options={[
                                        { value: true, label: t("yes") },
                                        { value: false, label: t("no") },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                name="comment"
                                label={t("applyModal.form.comment")}>
                                <Input.TextArea maxLength={260} showCount />
                            </Form.Item>
                            <Form.Item
                                rules={[
                                    {
                                        ...defaultFormRule,
                                        type: "boolean",
                                        validator: async (_, val) => {
                                            if (!val) throw new Error();
                                        },
                                    },
                                ]}
                                name="termsOfAgreement"
                                required
                                valuePropName="checked">
                                <Checkbox>
                                    {t("applyModal.form.preTermsOfAgreement")}{" "}
                                    <Link
                                        className="policy-link"
                                        target="_blank"
                                        href={"/policy"}
                                        rel="noreferrer">
                                        {t("applyModal.form.termsOfAgreement")}
                                    </Link>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="full-width-space flex-centered">
                        <Button
                            type="primary"
                            className="trigger-btn"
                            htmlType="submit">
                            {t("applyModal.form.submitText")}
                        </Button>
                    </div>
                </Form>
            </Modal>
            <Button className="trigger-btn" size="large" onClick={handleShow}>
                {t("applyModal.button")}
            </Button>
        </>
    );
};

export default ApplyModal;
