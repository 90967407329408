import React from "react";
import { Space } from "antd";

import instagram from "../assets/images/instagram.png";
import facebook from "../assets/images/facebook.png";
import telegram from "../assets/images/telegram.png";
import youtube from "../assets/images/youtube.png";
import tiktok from "../assets/images/tiktok.png";

const SocialProfiles = React.memo(() => {
    const openSocial = (path) => {
        window.open(path, "_blank");
    };

    const socials = [
        {
            key: "instagram",
            src: instagram,
            path: "https://instagram.com/10brygada_edelveys/?igshid=YmMyMTA2M2Y%3D",
        },
        {
            key: "facebook",
            src: facebook,
            path: "https://www.facebook.com/10brygada",
        },
        {
            key: "telegram",
            src: telegram,
            path: "https://t.me/edelweiss10tg",
        },
        {
            key: "youtube",
            src: youtube,
            path: "https://www.youtube.com/channel/UCBj-G6K37vpmuNsgaOSJCKA",
        },
        {
            key: "tiktok",
            src: tiktok,
            path: "https://www.tiktok.com/@10ogshbredelweys",
        },
    ];

    return (
        <Space className="full-width-space flex-centered" size={16} wrap>
            {socials.map(({ src, path, key }) => (
                <img
                    loading="lazy"
                    className="clickable"
                    alt={key}
                    src={src}
                    key={key}
                    style={{ height: 40, width: 40 }}
                    onClick={() => {
                        openSocial(path);
                    }}
                />
            ))}
        </Space>
    );
});

export default SocialProfiles;
