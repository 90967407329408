import { MOBILE_BREAKPOINT } from "../constants/layout";
import useWindowSize from "./useWindowSize";

const useIsMobileScreenWidth = () => {
    const { width } = useWindowSize();

    return width <= MOBILE_BREAKPOINT;
};

export default useIsMobileScreenWidth;
