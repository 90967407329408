import React, { useEffect, useRef, useState } from "react";
import Toolbar from "../components/site-container/Toolbar";

let timeout;
let scroll = 0;

const SiteContainer = React.memo(({ children = <></> }) => {
    const ref = useRef();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (timeout) {
                clearTimeout(timeout);
            }

            const toolbarEl = document.querySelector(".toolbar");

            const containerScroll =
                document.querySelector(".site-container").scrollTop;

            timeout = setTimeout(() => {
                if (scroll >= containerScroll && containerScroll > 10) {
                    toolbarEl.classList.add("sticky");
                } else if (!isMenuOpen) {
                    toolbarEl.classList.remove("sticky");
                }

                scroll = containerScroll;
            }, 10);
        };

        ref.current?.addEventListener("scroll", handleScroll);

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            ref.current?.removeEventListener("scroll", handleScroll);
        };
    }, [isMenuOpen]);

    return (
        <div
            className={`site-container ${isMenuOpen && "no-overflow"}`}
            ref={ref}>
            <Toolbar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            <div className="main-content">{children}</div>
        </div>
    );
});

export default SiteContainer;
