import { useTranslation } from "react-i18next";

import React from "react";
import Recruitment from "../components/Recruitment";
import SocialProfiles from "../components/SocialProfiles";

import "./Home.css";

const Home = React.memo(() => {
    const { t } = useTranslation();

    return (
        <>
            <div className="main-section">
                <div className="main-background">
                    <div className="title-container">
                        <div className="image-title">
                            <div style={{ fontWeight: 500, fontSize: 26 }}>
                                {t("joinText")}
                            </div>
                            <SocialProfiles />
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-width-1270">
                <Recruitment />
                <iframe
                    src="https://www.youtube.com/embed/rU5JOncYWC0"
                    title={t("youtubeTitle")}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen="allowfullscreen"
                    style={{
                        width: "100%",
                        aspectRatio: "16/9",
                    }}></iframe>
            </div>
        </>
    );
});

export default Home;
