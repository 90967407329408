import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/translationEN.json";
import translationUA from "./locales/translationUA.json";

const resources = {
    ua: {
        translation: translationUA,
    },
    en: {
        translation: translationEN,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "ua",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
