import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import useIsMobileScreenWidth from "../hooks/useIsMobile";

import Recruitment from "../components/Recruitment";
import ImagesCarousel from "../components/common/ImagesCarousel";

import Brigade1 from "../assets/images/brigade1.JPG";
import Brigade3 from "../assets/images/brigade3.jpg";
import Brigade4 from "../assets/images/brigade4.jpg";
import Brigade5 from "../assets/images/brigade5.jpg";
import Brigade6 from "../assets/images/brigade6.JPG";
import Brigade7 from "../assets/images/brigade7.JPG";
import Brigade8 from "../assets/images/brigade8.JPG";
import Brigade9 from "../assets/images/brigade9.JPG";
import Commander from "../assets/images/commander.png";

import "./About.css";

const About = React.memo(() => {
    const { t } = useTranslation();

    const isMobile = useIsMobileScreenWidth();

    const aboutCommander = t("commander.about", { returnObjects: true });
    const aboutBrigade = t("brigadeDescr", { returnObjects: true });

    const CommanderImage = (props) => (
        <img loading="lazy" {...props} src={Commander} alt="commander" />
    );

    const BrigadeCarousel = () => (
        <ImagesCarousel
            images={[
                Brigade1,
                Brigade3,
                Brigade4,
                Brigade5,
                Brigade6,
                Brigade7,
                Brigade8,
                Brigade9,
            ].map((src, idx) => (
                <img
                    key={idx}
                    src={src}
                    alt="commander"
                    style={{ maxWidth: 600, width: "100%", maxHeight: 600 }}
                />
            ))}
        />
    );

    return (
        <>
            <div className="max-width-1270">
                <div className="title">{t("commander.title")}</div>
                <Row className="full-width-space" gutter={24}>
                    <Col span={isMobile ? 24 : 12}>
                        <div
                            className="font-size-20"
                            style={{ color: "#398fe1", fontWeight: 500 }}>
                            {t("commander.name")}
                        </div>
                        {isMobile && (
                            <div className="full-width-space flex-centered">
                                <CommanderImage
                                    style={{
                                        paddingTop: 20,
                                        maxHeight: 500,
                                        minHeight: 250,
                                    }}
                                />
                            </div>
                        )}
                        <div className="section-text-wrapper">
                            {aboutCommander.map((i) => (
                                <li key={i}>{i}</li>
                            ))}
                        </div>
                    </Col>
                    {!isMobile && (
                        <Col span={12}>
                            <div className="full-width-space flex-centered">
                                <CommanderImage
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: 500,
                                    }}
                                />
                            </div>
                        </Col>
                    )}
                </Row>
                <span className="title" style={{ paddingTop: 20 }}>
                    {t("aboutBrigade")}
                </span>
                <Row className="full-width-space" gutter={24}>
                    <Col span={isMobile ? 24 : 12}>
                        {isMobile && <BrigadeCarousel />}

                        <div className="section-text-wrapper">
                            {aboutBrigade.map((i) => (
                                <div key={i}>{i}</div>
                            ))}
                        </div>
                    </Col>
                    {!isMobile && (
                        <Col span={12}>
                            <BrigadeCarousel />
                        </Col>
                    )}
                </Row>
                <Recruitment />
                <span className="title" style={{ paddingTop: 20 }}>
                    {t("trophiesExhibition")}
                </span>
                <iframe
                    loading="lazy"
                    src="https://mpembed.com/show/?m=8LKfKhh48di&mpu=212"
                    width="100%"
                    style={{ aspectRatio: "16/9" }}
                    title={t("trophiesExhibitionText")}
                    allowfullscreen="allowfullscreen"
                />
                <span className="title" style={{ paddingTop: 20 }}>
                    {t("museumOfMilitaryGlory")}
                </span>
                <iframe
                    loading="lazy"
                    src="https://mpembed.com/show/?m=fwSjWxxkehW&mpu=212"
                    width="100%"
                    style={{ aspectRatio: "16/9" }}
                    title={t("museumOfMilitaryGloryTitle")}
                    allowfullscreen="allowfullscreen"
                />
            </div>
        </>
    );
});

export default About;
