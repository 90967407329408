import React from "react";

import { useTranslation } from "react-i18next";

const TermsOfPolicy = React.memo(() => {
    const { t } = useTranslation();

    const terms = t("termsOfPolicy.terms", { returnObjects: true });

    return (
        <div className="max-width-1270">
            <div className="title">{t("termsOfPolicy.title")}</div>
            <ol>
                {terms.map(({ title, content }, idx) => (
                    <div key={`term-item-${idx}`}>
                        <li key={title}>{title}</li>
                        {content.map((c, idx) => (
                            <span key={`term-${idx}`}>{c}</span>
                        ))}
                    </div>
                ))}
            </ol>
        </div>
    );
});

export default TermsOfPolicy;
