import React from "react";
import { useTranslation } from "react-i18next";

import ApplyModal from "./ApplyModal";

const Recruitment = React.memo(() => {
    const { t } = useTranslation();

    return (
        <div className="flex-centered" style={{ flexDirection: "column" }}>
            <div
                style={{
                    fontSize: 30,
                    textAlign: "center",
                    fontWeight: 600,
                    paddingTop: 20,
                }}>
                {t("recruitment")}
            </div>
            <ApplyModal />
        </div>
    );
});

export default Recruitment;
